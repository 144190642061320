html {
	box-sizing: border-box;
	font-size: 18px;
	line-height: 1.5;
	-webkit-tap-highlight-color: transparent;
}

body {
	background: #fff;
	color: #3b4351;
	font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,sans-serif;
	font-size: .8rem;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
}

.h5, h5 {
	font-size: 1.3rem;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 500;
}

.text-muted {
	color: #949aa2;
}

.btn {
	font-size: 16px;
	border-radius: 2px;
	padding: .25rem .4rem;
}

.btn-primary {
	color: #fff;
	border-color: #1F7CAE;
	background: #1F7CAE;
}

.btn-primary:hover, 
.btn-primary:visited, 
.btn-primary:focus, 
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle,
.btn-primary.disabled, 
.btn-primary:disabled {
	background: #12628d;
	border-color: #12628d;
}

.btn-outline-primary,
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
	background: #fff;
	color: #1F7CAE;
	border-color: #1F7CAE;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
	color: #fff;
	background: #1F7CAE;
	border-color: #1F7CAE;
}

a, a:visited, .text-primary {
	color: #1F7CAE !important;
}

a:hover, a:focus, a:active,
.text-primary:hover, .text-primary:focus, .text-primary:active {
	color: #12628d !important;
}

label.form-label {
	margin-bottom: 3px;
	font-weight: 500;
}

.full-width {
	width: 100%;
}

.nav-toggle-icon {
	font-size: 18px;
	cursor: pointer;
	display: inline-block;
	position: relative;
	top: 2px;
}

.text-link {
	color: #1F7CAE;
	cursor: pointer;
}

.text-link:hover, .text-link:focus, .text-link:active {
	color:#12628d;
}

.card-title {
	color: #3b4351;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 3px;
}

.card-subtitle {
	color: #949aa2;
	font-size: 16px;
	font-weight: 400;
}

.card {
	border: none;
	border-radius: 0px;
	-webkit-box-shadow: 0 0.25rem 1rem rgba(48,55,66,.15);
	box-shadow: 0 0.25rem 0.6rem rgba(48,55,66,.15);
	margin-bottom: 10px;
}

.card-empty {
	border: none;
	border-radius: 0px;
	-webkit-box-shadow: none;
	box-shadow: none;
	margin-bottom: 10px;
	padding: 0px;
}

.card-empty .card-body {
	padding: 0px;
}

.card-body {
	padding: .8rem;
}

.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #fff;
	background-image: none;
	border: .05rem solid #bcc3ce;
	border-radius: .1rem;
	color: #3b4351;
	display: block;
	font-size: 0.9rem;
	height: 2rem;
	line-height: 1.2rem;
	max-width: 100%;
	outline: none;
	padding: .25rem .4rem;
	position: relative;
	transition: background .2s,border .2s,box-shadow .2s,color .2s;
	width: 100%;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
	background-color: #12628d;
}

.ant-steps-label-vertical .ant-steps-item-title {
	font-weight: 500;
}

.modal-title {
	font-size: 1.1rem;
}

.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot, 
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
	left: 0px;
	top: 1px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	font-size: 17px;
}

.ant-spin-dot-item {
	background-color: #1F7CAE;
}

.form-group {
	margin-bottom: 15px;
}

.btn-primary.disabled, 
.btn-primary:disabled {
	cursor: not-allowed;
}

.bold {
	font-weight: bold;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgb(173 173 173 / 50%);
}

.ant-message {
	z-index: 1090;
}

.line-through {
	text-decoration: line-through;
}

.items-list.ant-list-vertical .ant-list-item-meta-title {
	margin-bottom: 0px;
}

.items-list.ant-list-vertical .ant-list-item-meta {
	margin-bottom: 5px;
}

.items-list.ant-list-vertical .ant-list-item-action {
	margin-top: 0px;
}

.inline-block {
	display: inline-block;
}

.ant-table {
	font-size: 15px;
}

@media screen and (max-width: 576px){
	.ant-list-vertical .ant-list-item {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.ant-list-vertical .ant-list-item-extra {
		margin: 20px 0px 0px;
		width: 100%;
	}

	.ant-list-vertical .ant-list-item-extra .text-right { 
		text-align: left!important;
	}
}


@media print
{    
	.no-print, .no-print *
	{
		display: none !important;
	}

	.printFormatContainer {
		padding: 0px;
		position: absolute;
		left: -5px;
		top: -70px;
	}
	.printFormatResults {
		padding: 0px;
		position: absolute;
		left: -5px;
		top: -60px;
	}

	img {
		page-break-before: auto;
		page-break-after: auto;
		page-break-inside: avoid;
  	}
	
	tr.printApart td, tr.printApart th {
		page-break-inside: avoid;
  	}

}

@page  
{ 
	margin: 10mm 10mm 10mm 10mm;
	border-bottom: 5px solid #fff;
} 

.ant-pagination-item-active,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-open {
	border-color: #12628d;
}

.card-header {
	background-color: #fff;
}

.ant-rate {
	font-size: 35px;
}

.hint-text {
	font-size: 11px;
	color: #888;
}

span.ant-descriptions-item-label {
	font-weight: 600;
}

.ant-descriptions-small .ant-descriptions-row > th, .ant-descriptions-small .ant-descriptions-row > td {
	padding-bottom: 5px;
}

.ant-comment-inner {
	padding: 7px 10px;
	background-color: #f8f8f8;
	margin-bottom: 7px;
}

.ant-comment-actions { 
	margin-top: 5px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	height: 35px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #1F7CAE;
}

span.input-group-text {
	border-radius: 0px;
	padding: 3px 10px;
	border: 1px solid #e4e4e4;
}

ant-tabs-nav {
	margin-bottom: -1px !important;
}

.card-header {
	font-weight: 600;
}

:where(.css-dev-only-do-not-override-diro6f).ant-descriptions .ant-descriptions-row >th, :where(.css-dev-only-do-not-override-diro6f).ant-descriptions .ant-descriptions-row >td {
	padding-bottom: 0px;
}

button.btn.btn-sm {
	font-size: 13px;
	font-weight: 500;
}

:where(.css-dev-only-do-not-override-diro6f).ant-empty .ant-empty-description {
	color: #8e949a;
}

p {
	line-height: .9rem;
}

p.item-label {
	color: rgba(0, 0, 0, 0.45);
	font-weight: normal;
	font-size: 14px;
	line-height: 15px;
	text-align: start;
	margin-top: 5px;
	margin-bottom: 3px;
}

.ant-popover.ant-popconfirm {
	z-index: 10000;
}

.b-0 {
	border: none !important;
}
.br-0 {
	border-right-width: 0!important;
}
.bb-0 {
	border-bottom-width: 0!important;
}
.bt-0 {
	border-top-width: 0!important;
}